<template>
  <nav :class="$style.menu">
    <router-link
      data-testid="dashboardLink"
      :class="$style.containerDashboard"
      :active-class="$style.active"
      :to="links.dashboard"
      @click="openDashboard"
    >
      <XIcon
        icon="home"
        left
      />
      <span :class="$style.titleDashboard">
        {{ $t('layout.sidebar.navigation.dashboard') }}
      </span>
    </router-link>
    <SidebarAccordionContainer
      :bounces="bounces"
      :downloads="downloads"
      :links="links"
    />
    <router-link
      v-if="settingsUrl"
      data-testid="configurationMenuOption"
      :class="$style.containerConfig"
      :active-class="$style.active"
      :to="settingsUrl"
      @click="openSettings"
    >
      <XIcon
        icon="cog"
        left
      />
      <span>
        {{ $t('layout.sidebar.navigation.settings') }}
      </span>
    </router-link>
  </nav>
</template>

<script>
import { useVerifyLocation } from '@account/composables';
import useGA from '@base/hooks/useGA';
import SidebarAccordionContainer from '@base/containers/layout-default/SidebarAccordionContainer.vue';

export default {
  name: 'SidebarNavigationMenu',
  components: {
    SidebarAccordionContainer,
  },
  props: {
    bounces: {
      type: Number,
      default: 0,
    },
    downloads: {
      type: Object,
      default: () => ({}),
    },
    links: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    settingsUrl() {
      const isNationalUser = useVerifyLocation().isNationalUser.value;

      if (isNationalUser) {
        return this.links.settings;
      }

      return this.links.memberships;
    },
  },
  methods: {
    useGA,
    dispatchGA(eventAction) {
      this.useGA('event', eventAction, 'menu-app');
    },
    openDashboard() {
      this.dispatchGA('listDashboardClick');
      localStorage.setItem('scrollTopNavigation', 0);
    },
    openSettings() {
      this.dispatchGA('listConfigurationClick');
    },
  },
};
</script>

<style lang="scss" module>
.menu {
  overflow-y: auto;
  padding-bottom: var(--space-large-xxx);
}

.containerDashboard {
  display: flex;
  align-items: center;
}

.containerConfig {
  position: absolute;
  z-index: 3;
  bottom: 0;
  width: 100%;
  background-color: var(--color-brand-support-03-200);
}

.containerDashboard,
.containerConfig {
  padding: var(--space-small-x);
  font-size: var(--font-size-regular);
  color: var(--color-neutral-800);
  border-top: var(--border-width-small) solid var(--color-brand-support-03-500);

  &:hover {
    background-color: var(--color-brand-support-03-400);
  }

  &.active {
    background-color: var(--color-brand-support-03-500);
    cursor: default;
  }

  &:hover,
  &:focus,
  &.active {
    color: var(--color-neutral-1000);
  }

  &:active {
    color: var(--color-brand-support-01-500);
    background-color: var(--color-neutral-400);
    transition-delay: var(--time-delay-base);
  }
}
</style>
