<template>
  <li
    :class="$style.listItem"
    @click.capture="openItem($event)"
  >
    <router-link
      :class="[$style.item, badgeNotification?.processing && $style.isLoading]"
      :data-testid="item.dataTestid"
      :to="{ path: link, query: item.params }"
      :exact-active-class="$style.active"
    >
      <div :class="$style.containerIconBadge">
        <XIcon
          :icon="item.icon"
          :icon-style="item.iconStyle"
          fixed-width
          left
        />
        <XBadge
          v-if="badgeAlert || hasBadgeNotification"
          :class="$style.iconBadge"
          small
          v-bind="getBadgeData"
        >
          <span v-if="hasBadgeNotification">
            {{ badgeNotification.notify }}
          </span>
        </XBadge>
      </div>

      <span :class="$style.itemText">
        {{ $t(`layout.sidebar.navigation.${item.name}`) }}
      </span>

      <XIcon
        v-if="badgeNotification && badgeNotification.processing"
        class="fa-pulse"
        icon="spinner"
        right
      />

      <XBadge
        v-if="hasBetaBadge"
        :class="$style.badge"
        color="var(--color-feedback-info-400)"
        text-color="var(--color-neutral-0)"
        icon-position="right"
        icon="gem"
        small
        text-on-hover
      >
        {{ $t('general.feature.beta') }}!
      </XBadge>
    </router-link>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import useGA from '@base/hooks/useGA';

import { openBlockedByAdminModalKey } from '@base/keys';

export default defineComponent({
  name: 'SidebarAccordionItem',
  inject: {
    openBlockedByAdminModal: { from: openBlockedByAdminModalKey },
  },
  props: {
    link: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({ sidebarBadges: 'info/getSidebarBadges' }),
    hasBetaBadge() {
      return this.item.beta;
    },
    hasUpgradeBadge() {
      return this.item.upgrade;
    },
    badgeAlert() {
      return this.sidebarBadges[this.item.alert];
    },
    badgeNotification() {
      return this.sidebarBadges[this.item.notification];
    },
    hasBadgeNotification() {
      return !this.badgeAlert && this.badgeNotification && this.badgeNotification.notify > 0;
    },
    getBadgeData() {
      return {
        color: this.badgeAlert
          ? 'var(--color-feedback-error-400)'
          : 'var(--color-feedback-warning-400)',
        textColor: this.badgeAlert ? 'var(--color-neutral-0)' : 'var(--color-neutral-1000)',
        icon: this.badgeAlert ? 'exclamation' : '',
        iconStyle: this.badgeAlert ? 'fak' : undefined,
      };
    },
  },
  methods: {
    useGA,
    dispatchGA() {
      const items = {
        rootFolder: 'listDocumentsAllClick',
        runningDocuments: 'listDocumentsInProgressClick',
        closedDocuments: 'listDocumentsCompletedClick',
        canceledDocuments: 'listDocumentsCancelledClick',
        downloads: 'listDocumentsDownloadClick',
        draftPacks: 'listDocumentsDraftsClick',
        trashBin: 'listDocumentsTrashClick',
        reports: 'listManagementReportsClick',
        trackingNotifications: 'listManagementEmailsSentClick',
        contacts: 'listManagementContactsClick',
        templates: 'listAutomationTemplatesClick',
        formBuilders: 'listAutomationFormBuilderClick',
        formFlows: 'listAutomationFlowsClick',
        spreadsheetFlows: 'listAutomationSpreadsheetFlowsClick',
        flowProcesses: 'listAutomationFlowProcessesClick',
        dashboardDocumentDetails: 'list-dash-document-details-click',
        acceptanceTermWhatsappIndex: 'list-accept-whatsapp-click',
        pendingAnswers: 'list-pendencies-second-form-click',
      };
      const eventAction = items[this.item.name];
      this.useGA('event', eventAction, 'menu-app');
    },
    openItem(event) {
      this.dispatchGA();
      if (!this.item.functionality) return;

      event.preventDefault();
      this.openBlockedByAdminModal(this.item.functionality, () => {
        this.$router.push({ path: this.link, query: this.item.params });
      });
    },
  },
});
</script>

<style lang="scss" module>
.item {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
  margin: 0 auto;
  padding: var(--space-small-xx) var(--space-small-x);
  font-size: var(--font-size-regular);
  text-decoration: none;
  user-select: none;
  list-style: none;
  color: var(--color-neutral-800);
  cursor: pointer;

  &.isLoading {
    color: var(--color-neutral-500);
    cursor: wait;
  }

  &:not(.isLoading) {
    &:hover {
      background-color: var(--color-brand-support-03-400);
    }

    &.active {
      background-color: var(--color-brand-support-03-500);
      cursor: default;
    }

    &:hover,
    &:focus,
    &.active {
      color: var(--color-neutral-1000);
    }

    &:active {
      color: var(--color-brand-support-01-500);
      background-color: var(--color-neutral-400);
      transition-delay: var(--time-delay-base);
    }
  }
}

.itemText {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badge {
  margin-left: auto;
}

.containerIconBadge {
  position: relative;

  .iconBadge {
    --size: 18px;

    position: absolute;
    top: 0px;
    right: 0px;
    width: var(--size);
    height: var(--size);
    font-size: var(--font-size-small-x);
    transform: translateY(-40%);
  }
}
</style>
