<template>
  <span
    v-show="open"
    :class="$style.close"
    @click="$emit('close')"
  >
    <XIcon icon="times" />
  </span>
</template>

<script>
export default {
  name: 'SidebarCloseButton',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.close {
  position: absolute;
  top: var(--space-regular);
  right: -42px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: var(--color-neutral-0);
  background: var(--color-brand-secondary-400);
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
}

@include breakpoint('large') {
  .close {
    display: none;
  }
}
</style>
