<template>
  <div :class="$style.container">
    <p :class="$style.text">
      {{ $t('subscription.blockedFunctionality.batchDownload.firstText') }}
    </p>
    <strong :class="$style.text">
      {{ $t('subscription.blockedFunctionality.batchDownload.lastText') }}
    </strong>
  </div>
</template>

<script>
export default {
  name: 'BatchDownloadFunctionality',
};
</script>

<style lang="scss" module>
.container {
  margin-top: var(--space-large);

  .title {
    margin-bottom: var(--space-large);
  }

  .text {
    display: block;
    margin-bottom: var(--space-regular);
  }
}
</style>
