<template>
  <div :class="$style.layout">
    <main :class="$style.main">
      <LocalRouterView v-if="$route.meta && $route.meta.rails" />
      <router-view
        v-else
        v-bind="legacyPropsByRoute"
        :key="$route.fullPath"
      />
    </main>
    <LayoutModalsContainer :functionality-modal="functionalityModal" />
  </div>
</template>

<script>
import { defineComponent, provide, ref } from 'vue';

import { openBlockModalKey } from '@base/keys';
import { useFunctionalities, useLayoutHeightCssVars, useValidateAccountActions } from '@base/hooks';
import LayoutModalsContainer from '@/modules/base/containers/layout-default/LayoutModalsContainer.vue';
import { useLegacyProps } from '@base/hooks/useLegacyProps';

export default defineComponent({
  name: 'BaseCleanLayout',
  components: { LayoutModalsContainer },
  setup() {
    const functionalityModal = ref(null);

    const { isBlocked: isBlockedFunctionality } = useFunctionalities();

    const openBlockFunctionalityModal = (functionality, fn) => {
      const { handleOpenActionModal } = useValidateAccountActions();

      functionalityModal.value = functionality;

      if (isBlockedFunctionality(functionality)) {
        return handleOpenActionModal('showBlockedFunctionality');
      }

      return fn();
    };

    provide(openBlockModalKey, openBlockFunctionalityModal);
    const { legacyPropsByRoute } = useLegacyProps();
    useLayoutHeightCssVars();

    return { functionalityModal, legacyPropsByRoute };
  },
});
</script>

<style lang="scss" module>
.main {
  transition: all ease var(--animate-duration);
}
</style>
