/* eslint-disable no-param-reassign */
import Vue from 'vue';

import routesViews from '@base/routes/routes';

import RouterView from '@base/components/RouterView.vue';
import { useStore } from '@/store';

Vue.component('LocalRouterView', RouterView);

const useRedirect = ({ layoutData, viewName, pathName, viewProps }) => {
  const store = useStore();

  const changeComponent = (component, props, path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    store.dispatch('info/setRouter', {
      path,
      component: {
        props,
        constructor: component.default,
      },
    });
  };

  const createPrototypes = () => {
    Vue.prototype.$goTo = (url) => {
      window.location.href = url;
    };
  };

  const initRouterView = async () => {
    const queryParams = layoutData.params;
    const { component, props, isVueRouter } = await routesViews({
      viewName,
      path: pathName,
      queryParams,
    });
    if (isVueRouter) return;
    changeComponent(component, props || viewProps, pathName);
  };

  const init = () => {
    createPrototypes();
    initRouterView();
  };
  return { init };
};

export { useRedirect };
