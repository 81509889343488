<template>
  <span>
    {{ text }}
    <XLink
      no-padding
      target="_blank"
      :to="link"
    >
      {{ linkText }}
    </XLink>
  </span>
</template>

<script>
import { useI18n } from '@/locales';
import { STATUS_LINK } from '@base/constants/help';

const { t } = useI18n();

export default {
  props: {
    text: { type: String, default: '' },
    link: { type: String, default: STATUS_LINK },
    linkText: { type: String, default: t('general.statusAlert.link') },
  },
};
</script>
