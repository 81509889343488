<template>
  <XButton
    model="icon"
    design="link"
    @click="handleHubspot"
  >
    <XIcon
      size="lg"
      icon="comment"
    />
  </XButton>
</template>

<script>
import { loadHubspot, openHubspotChat } from '@base/utils/hubspot';
import { useGA } from '@base/hooks';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeaderHubspotWidget',
  setup() {
    loadHubspot();

    const handleHubspot = () => {
      useGA('event', 'buttonHelpIconClick', 'menu-app');
      openHubspotChat();
    };

    return { handleHubspot };
  },
});
</script>
