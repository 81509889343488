<script setup>
import { computed } from 'vue';
import { camelCase } from 'lodash';
import { useI18n } from '@/locales';

const props = defineProps({
  functionality: {
    type: String,
    default: '',
  },
  value: {
    type: Boolean,
    default: false,
  },
});

const { t } = useI18n();

const blockedFeatureTitle = computed(() =>
  props.functionality?.length > 0
    ? t(`subscription.blockedByAdminModal.blockedFeatureTitle.${camelCase(props.functionality)}`)
    : ''
);
</script>

<template>
  <XModal
    :is-open="value"
    :content-classes="[$style.contentContainer]"
    :actions-classes="[$style.actionsContainer]"
    :width="544"
    @close="$emit('close')"
  >
    <XIcon
      :class="$style.icon"
      icon="exclamation-triangle"
      icon-style="fad"
      size="3x"
    />
    <h4 :class="$style.title">
      {{ $t('subscription.blockedByAdminModal.title', { blockedFeatureTitle }) }}
    </h4>
    <span :class="$style.description">
      {{ $t('subscription.blockedByAdminModal.description') }}
    </span>
    <template #actions>
      <XButton
        data-testid="closeButton"
        :class="$style.actionButton"
        @click="$emit('close')"
      >
        {{ $t('subscription.blockedByAdminModal.action') }}
      </XButton>
    </template>
  </XModal>
</template>

<style lang="scss" module>
.contentContainer,
.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon {
  margin-bottom: var(--space-regular);
  color: var(--color-brand-primary-400);
}

.title {
  margin-bottom: var(--space-small-xx);
  text-align: center;
}

.description {
  text-align: center;
  color: var(--color-neutral-800);
}

.actionButton {
  width: fit-content;
}
</style>
