<script setup>
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useI18n } from '@/locales';

const props = defineProps({
  account: {
    type: Object,
    default: () => ({}),
  },
  dashboardUrl: {
    type: String,
    default: '',
  },
  organization: {
    type: Object,
    default: () => ({}),
  },
  workspacesUrl: {
    type: String,
    default: '',
  },
});

const { t } = useI18n();

const isOpen = ref(false);
const dropdownMenu = ref(null);
const ITEMS_TO_SHOW = 8;

const flatOrganizationData = computed(() => {
  const reducedOrganizationData = props.organization?.workspaces?.reduce(
    (previous, nextWorkspace) => {
      return [
        ...previous,
        {
          id: `workspace${nextWorkspace.id}`,
          name: nextWorkspace.name,
          isSeparator: true,
        },
        ...nextWorkspace.accounts,
      ];
    },
    []
  );

  return reducedOrganizationData.map((item) => ({
    ...item,
    text: item.name,
    slot: 'item',
  }));
});

const options = computed(() => {
  const firstItems = flatOrganizationData.value.slice(0, ITEMS_TO_SHOW);
  const defaultOptions = [
    {
      id: 'backToWorkspaceList',
      text: t('workspaces.sideBarOrganizationAccountsMenu.backToWorkspaceList'),
      url: props.workspacesUrl,
      isSeparator: false,
      icon: 'arrow-left',
      slot: 'item',
    },
    ...firstItems,
  ];

  const seeMoreOption = {
    id: 'seeMore',
    text: t('general.action.seeMore'),
    url: props.workspacesUrl,
    isSeparator: true,
    icon: 'plus',
    slot: 'item',
  };

  if (flatOrganizationData.value.length > ITEMS_TO_SHOW) {
    return [...defaultOptions, seeMoreOption];
  }

  return defaultOptions;
});

const handleClickMenuItem = (item) => {
  if (item.url) {
    // TODO: Search for a better approach using router.push and replace window.location.href
    window.location.href = item.url;
  }
};

onClickOutside(dropdownMenu, () => {
  isOpen.value = false;
});
</script>

<template>
  <div :class="$style.organizationAccountsMenu">
    <XDropdownMenu
      ref="dropdownMenu"
      :class="$style.organizationAccountsDropdown"
      full-width
      :options="options"
      @click.native="isOpen = !isOpen"
      @after:close="isOpen = false"
    >
      <template #activator="{ on }">
        <div :class="$style.organizationAccountsDropdownActivator">
          <a
            :class="$style.organizationLogoLink"
            :href="dashboardUrl"
            :style="{
              backgroundColor: organization.brand?.color1,
            }"
          >
            <img
              :src="organization.brand?.links?.logoUrl"
              object-fit="scale-down"
            />
          </a>
          <XButton
            :class="$style.organizationButton"
            data-testid="organizationAccountsMenuActivator"
            model="plain"
            full-width
            @click="on"
          >
            <div :class="$style.organizationData">
              <span
                :class="$style.workspaceName"
                :title="account.workspace.name"
              >
                {{ account.workspace.name }}
              </span>
              <span
                :class="$style.accountName"
                :title="account.name"
              >
                {{ account.name }}
              </span>
            </div>
            <XIcon
              :class="$style.accountsMenuActivatorIcon"
              :icon="isOpen ? 'chevron-up' : 'chevron-down'"
            />
          </XButton>
        </div>
      </template>
      <template #slot-item="{ item }">
        <!-- TODO: remove style width when is possible define width using props -->
        <XListItem
          :key="item.id"
          style="width: 296px"
          :tag="item.url ? 'a' : 'div'"
          :href="item.url"
          :separator="item.isSeparator"
          :icon="item.icon"
          ellipsis
          @click.native="handleClickMenuItem(item)"
        >
          {{ item.text }}
        </XListItem>
      </template>
    </XDropdownMenu>
  </div>
</template>

<style lang="scss" module>
.organizationAccountsMenu {
  display: flex;
  align-items: center;
  padding: 0;
  border-bottom: var(--border-width-small) solid var(--color-neutral-1000);

  .organizationLogoLink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: var(--space-small-xxx);
    border: var(--border-width-small) solid var(--color-neutral-1000);
    border-radius: var(--border-radius-medium);
  }

  .organizationAccountsDropdown {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 64px;
    padding-left: var(--space-regular);
    color: var(--color-neutral-0);

    // TODO: Remove when XDropdownMenu has prop full-width

    :first-child {
      flex-grow: 1;
    }

    .organizationAccountsDropdownActivator {
      display: flex;
      padding-right: var(--space-regular);
      gap: var(--space-small-xx);
    }

    .organizationButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      text-align: left;

      .organizationData {
        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        max-width: 154px;
        gap: var(--space-small-xxx);
        text-overflow: ellipsis;
        white-space: nowrap;

        .workspaceName {
          overflow: hidden;
          font-size: var(--font-size-small-x);
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--color-neutral-1000);
        }

        .accountName {
          overflow: hidden;
          font-size: var(--font-size-small);
          font-weight: var(--font-weight-semibold);
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--color-brand-secondary-400);
        }
      }

      .accountsMenuActivatorIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: var(--space-small-xx);
        color: var(--color-brand-secondary-400);
      }
    }
  }
}
</style>
