<template>
  <component
    :is="$route.meta.layout || layout"
    :key="$route.meta.layout || layout"
  />
</template>

<script>
import BaseCleanLayout from '@base/layouts/BaseCleanLayout.vue';
import BaseDefaultLayout from '@/modules/base/layouts/BaseDefaultLayout.vue';
import BaseNavigationLayout from '@/modules/base/layouts/BaseNavigationLayout.vue';
import BaseErrorLayout from '@/modules/base/layouts/BaseErrorLayout.vue';
import { verifyIncidentStatusAlert } from '@base/utils/incident-status-alert';
import { defineComponent, onUpdated } from 'vue';

export default defineComponent({
  components: {
    BaseCleanLayout,
    BaseDefaultLayout,
    BaseNavigationLayout,
    BaseErrorLayout,
  },
  props: {
    layout: {
      type: String,
      default: 'BaseNavigationLayout',
    },
  },
  setup() {
    onUpdated(verifyIncidentStatusAlert);
  },
});
</script>
