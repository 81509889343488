// VUE
import Vue from 'vue';

// LAYOUTS
import AppLayout from '@/modules/base/layouts/AppLayout.vue';

// PLUGINS
import { camelizeKeys } from '@base/utils';
import { getViewDataset } from '@base/utils/getViewDataset';
import '@/plugins/vue-after-redirect-actions';

import '@/plugins/feature-toggle';
import '@/plugins/sentry';

// LOCALES
import i18n from '@/locales';
import router from '@/plugins/vue-router';

// DIRECTIVES
import '@base/directives';

// LEGACY
import axiosLegacy from 'axios';

import { useRedirect } from '@base/hooks/useRedirect';
import { axios } from '@/plugins';
import store from '@/store';

// FEATURE FLAG
import FeatureFlag from '@base/components/FeatureFlag/FeatureFlag';

import { legacyPropsKey } from '@base/keys';

Vue.component('FeatureFlag', FeatureFlag);

const parseDatasetToProps = (dataset) =>
  _.mapValues(dataset, (value) => {
    const isParsableString = !_.isError(_.attempt(JSON.parse.bind(null, value)));
    return isParsableString ? JSON.parse(value) : value;
  });

// Remove localStorage account cache
localStorage.removeItem('account');

Vue.prototype.jQuery = jQuery;

// RENDER
(async function initApplication() {
  const [csRfTokenTag] = document.getElementsByName('csrf-token');
  const csRfTokenContent = csRfTokenTag ? csRfTokenTag.getAttribute('content') : '';
  Object.assign(
    axios.defaults.headers.common,
    { Accept: 'application/json' },
    csRfTokenTag && { 'X-CSRF-Token': csRfTokenContent }
  );
  //
  axiosLegacy.defaults.headers.common = axios.defaults.headers.common;

  const layoutDataElement = document.getElementById('app');

  const parseKeys = (data) => {
    try {
      const dataObject = JSON.parse(data);
      return camelizeKeys(parseDatasetToProps(dataObject));
    } catch (error) {
      return data;
    }
  };

  const viewDataSet = getViewDataset();

  const layoutData = layoutDataElement ? parseKeys(layoutDataElement.dataset.layoutData) : {};

  new Vue({
    i18n,
    store,
    toast: true,
    router,
    provide: {
      [legacyPropsKey]: viewDataSet.viewProps,
    },
    created() {
      const { init } = useRedirect({
        layoutData,
        ...viewDataSet,
      });
      init();
    },
    render: (h) => h(AppLayout, { props: { layout: layoutDataElement.dataset.layout } }),
  }).$mount('#app');
})();
