<template>
  <Component :is="tag">
    <slot :is-enabled="isEnabled" />
  </Component>
</template>

<script>
import { useStore } from '@/store';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    toggle: {
      type: String,
      default: '',
    },
    scope: {
      type: String,
      default: undefined,
    },
    getterPath: {
      type: String,
      default: 'info/isToggleEnabled',
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  setup(props) {
    const store = useStore();
    const isToggleEnabled = computed(() => store.getters[props.getterPath]);

    const isEnabled = computed(() => isToggleEnabled.value(props.toggle, { scope: props.scope }));

    return { isEnabled };
  },
});
</script>
