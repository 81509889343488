<template>
  <div
    :class="[$style.content, account.trialEnding && $style.expired]"
    data-testid="subscriptionAlertCard"
    @click="goToPlans"
  >
    <XIcon
      icon="clock"
      size="2x"
      :class="$style.icon"
    />
    <div :class="$style.textContainer">
      <b
        :class="$style.title"
        data-testid="subscriptionAlertMsg"
      >
        {{ trialExpiresText }}
      </b>
      <span :class="$style.purchase">
        {{ $t('dashboard.trialSubscriptionAlert.purchase') }}
      </span>
    </div>
    <XButton
      size="sm"
      design="outlined"
      :class="$style.subscribeButton"
      :data-testid="dataTestid"
      @click="goToPlans"
    >
      {{ $t('dashboard.trialSubscriptionAlert.subscribe') }}
      <XIcon
        right
        icon="arrow-right"
      />
    </XButton>
  </div>
</template>

<script>
import { useGA } from '@base/hooks';
import { useI18n } from '@/locales';
import { defineComponent, computed } from 'vue';
import useHotjar from '@/modules/base/hooks/useHotjar';

export default defineComponent({
  name: 'SubscriptionAlert',
  props: {
    account: {
      type: Object,
      default: () => ({}),
    },
    link: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { t } = useI18n();

    const trialExpiresText = computed(() => {
      if (props.account.enabled) {
        return props.account.trialExpiresText;
      }
      return t('dashboard.trialSubscriptionAlert.expired');
    });

    const dataTestid = computed(() =>
      props.account.trialEnding ? 'expiredGoToPlansButton' : 'activeGoToPlansButton'
    );

    const goToPlans = () => {
      useGA('event', 'buttonNewCheckoutClick', 'menu-app');
      useHotjar('event', 'navNewContractPlan');
      window.location.href = props.link;
    };

    return {
      goToPlans,
      dataTestid,
      trialExpiresText,
    };
  },
});
</script>
<style lang="scss" module>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: var(--space-small-xx);
  font-size: var(--font-size-small);
  color: var(--color-brand-secondary-400);
  background-color: var(--color-brand-support-02-50);
  box-shadow: var(--shadow-bottom-medium);
  cursor: pointer;

  &.expired {
    background-color: var(--color-brand-primary-50);
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    margin-left: var(--space-regular);

    .title {
      text-transform: uppercase;
    }
  }

  .subscribeButton {
    margin-left: var(--space-large);
  }
}

@include breakpoint('extra-small') {
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    .icon {
      display: none;
    }

    .textContainer {
      margin: 0 var(--space-regular) var(--space-small-xx);
      text-align: center;

      .purchase {
        display: none;
      }
    }

    .subscribeButton {
      margin-left: 0;
    }
  }
}
</style>
