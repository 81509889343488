<template>
  <div>
    <MobileAlertModal
      :show="isModalActive('mobileAlert')"
      @close="handleCloseModal"
    />
    <EmailVerificationModal
      :show="isModalActive('showEmailVerificationModal')"
      :user="user"
      @message-feedback="handleMessageFeedback"
      @close="handleCloseModal"
    />
    <TrialExpiredModal
      :show="isModalActive('showTrialExpiredModal')"
      :plans-link="links.plans"
      @close="handleCloseModal"
    />
    <ModalBlockedFunctionality
      :show="isModalActive('showBlockedFunctionality')"
      :plans-link="links.plans"
      :functionality="functionalityModal || functionality"
      @close="handleCloseModal"
    />
    <LimitExceddedInTrialModal
      data-testid="limitExceddedInTrialModal"
      :context="currentModalContext"
      :submited-data="submitedData"
      :plans-link="links.plans"
      :user="user"
      :account="account"
      :value="isModalActive('limitExceddedInTrialModal')"
      :loading="loading"
      @handle-limit-excedded-in-trial-form-submit="sendForm($event)"
      @close="closeLimitExceddedInTrialModal"
    />
    <BlockedByAdminModal
      data-testid="blockedByAdminModal"
      :value="isModalActive('blockedByAdminModal')"
      :functionality="functionalityModal"
      @close="handleCloseModal"
    />
  </div>
</template>
<script>
import { watch, toRefs } from 'vue';
import { mapGetters } from 'vuex';
import { sendHubspotEvent } from '@base/utils/hubspot';
import { useValidateAccountActions, useModal } from '@base/hooks';
import LimitExceddedInTrialModal from '@/modules/account/components/trial/LimitExceddedInTrialModal/LimitExceddedInTrialModal.vue';
import MobileAlertModal from '@base/components/layout-default/mobile/MobileAlertModal.vue';
import EmailVerificationModal from '@account/components/EmailVerification/EmailVerificationModal/EmailVerificationModal.vue';
import TrialExpiredModal from '@account/components/trial/TrialExpiredModal/TrialExpiredModal.vue';
import ModalBlockedFunctionality from '@account/components/subscription/modal/ModalBlockedFunctionality.vue';
import BlockedByAdminModal from '@account/components/BlockedByAdminModal/BlockedByAdminModal.vue';

export default {
  name: 'LayoutModalsContainer',
  components: {
    MobileAlertModal,
    EmailVerificationModal,
    TrialExpiredModal,
    ModalBlockedFunctionality,
    LimitExceddedInTrialModal,
    BlockedByAdminModal,
  },
  props: {
    functionalityModal: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { modalData, handleRemoveAction } = useValidateAccountActions();
    const { isModalActive, closeModal, openModal } = useModal();

    const { currentModalContext, currentActionModal, functionality } = toRefs(modalData);

    watch(currentActionModal, openModal, { immediate: true });

    return {
      handleRemoveAction,
      isModalActive,
      closeModal,
      openModal,
      currentModalContext,
      functionality,
    };
  },
  data() {
    return {
      submitedData: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      links: 'info/getLinks',
      user: 'info/getUser',
      account: 'info/getAccount',
    }),
    openSidebarStyle() {
      return this.openSidebar && this.$style.openSidebar;
    },
    hubspotEvent() {
      const events = {
        createDocument: 'hubspotIncreaseTrialDocumentsLimitFormId',
        createWhatsappAcceptance: 'hubspotIncreaseTrialWhatsappAcceptanceLimitFormId',
      };
      return events[this.currentModalContext];
    },
  },
  methods: {
    handleMessageFeedback(kind, message) {
      this.$toast[kind](message);
    },
    async sendForm(user) {
      this.loading = true;
      try {
        await sendHubspotEvent(this.hubspotEvent, {
          contact: {
            email: user.email,
            phone: user.phoneNumber,
            account_id: this.account?.id,
          },
        });
        this.submitedData = true;
      } finally {
        this.loading = false;
      }
    },
    closeLimitExceddedInTrialModal(context) {
      const sessionStorageKey =
        context === 'createDocument'
          ? 'requestTrialExtensionDocumentApp'
          : 'requestTrialExtensionWhatsappAcceptance';
      sessionStorage.setItem(sessionStorageKey, true);

      this.handleCloseModal();
    },
    handleCloseModal() {
      this.handleRemoveAction();
      this.submitedData = false;
      this.closeModal();
    },
  },
};
</script>
