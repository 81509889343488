<script>
export default {
  name: 'BaseErrorLayout',
};
</script>

<template>
  <main :class="$style.errorContainer">
    <LocalRouterView v-if="$route.meta && $route.meta.rails" />
    <router-view
      v-else
      :key="$route.fullPath"
    />
  </main>
</template>

<style lang="scss" module>
.errorContainer {
  transition: all ease var(--time-transition-base);
}
</style>
