<template>
  <div :class="$style.container">
    <p :class="$style.text">
      {{ $t('subscription.blockedFunctionality.accountAudit.firstText') }}
    </p>
    <strong>
      {{ $t('subscription.blockedFunctionality.availableOnPlusPlan') }}
    </strong>
  </div>
</template>

<script>
export default {
  name: 'AccountAuditFunctionality',
};
</script>

<style lang="scss" module>
.container {
  margin-top: var(--space-regular);

  .text {
    margin-bottom: var(--space-medium);
  }
}
</style>
