import { ref, watch } from 'vue';
import { useResizeObserver } from '@vueuse/core';

export function useLayoutHeightCssVars(headerElementsRef) {
  const headerHeight = ref(0);

  const computeHeight = () => {
    headerHeight.value = headerElementsRef.reduce((height, headerElementRef) => {
      const headerElement = headerElementRef.value?.$el || headerElementRef.value;

      if (headerElement) {
        return height + headerElement.offsetHeight || 0;
      }

      return height;
    }, 0);
  };

  const computeCssVars = (height) => {
    document.body.style.setProperty('--layout-header-height', `${height}px`);

    document.body.style.setProperty(
      '--layout-main-height',
      `calc(100vh - var(--layout-header-height))`
    );
  };

  watch(headerHeight, (height) => computeCssVars(height), { immediate: true });

  if (!headerElementsRef?.length) return;

  watch(headerElementsRef, computeHeight);

  headerElementsRef.forEach((headerElementRef) =>
    useResizeObserver(headerElementRef, computeHeight)
  );
}
