import Vue from 'vue';
import { maskText } from '@base/utils/mask';

const install = () => {
  Vue.directive('format', {
    inserted(el, binding) {
      const { textContent, formated } = el;
      const { value } = binding;
      if (textContent !== formated) {
        const formatedText = maskText(textContent, value);
        Object.assign(el, { textContent: formatedText, formated: formatedText });
      }
    },
  });
};

export default { install };
