import store from '@/store';
import { useToast } from '@base/hooks';
import BaseIncidentStatusToast from '@base/components/BaseIncidentStatusToast/BaseIncidentStatusToast.vue';
import { useSessionStorage } from '@vueuse/core';
import { sentry } from '@/plugins/sentry';

const TOAST_TIMEOUT = 10000; // ms

/**
 * Utilitary function to show incident status alert if necessary
 * @example
 * // Requires toast instance be fully loaded
 * onUpdate(verifyIncidentStatusAlert);
 * */
export const verifyIncidentStatusAlert = () => {
  try {
    const toast = useToast();
    const toastSeen = useSessionStorage('statusAlertToastSeen', false);
    const alertText = store.state.info?.account?.statusAlert;
    const hasStatusAlert = !!alertText;
    if (hasStatusAlert && !toastSeen?.value) {
      const component = {
        component: BaseIncidentStatusToast,
        props: {
          text: alertText,
        },
      };
      toast.warning(component, {
        position: 'bottom-right',
        pauseOnHover: true,
        closeOnClick: false,
        timeout: TOAST_TIMEOUT,
      });
      toastSeen.value = true;
    }
  } catch (error) {
    sentry.captureException(error);
  }
};
