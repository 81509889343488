<template>
  <XModal
    :is-open="show"
    close-button
    data-testid="mobileAlertModal"
    :title="$t('layout.mobileAlert.header')"
    @close="$emit('close')"
  >
    <div :class="$style.content">
      <XIcon
        icon="desktop-alt"
        size="3x"
      />
      <span>{{ $t('layout.mobileAlert.description') }}</span>
    </div>

    <template #actions>
      <XButton @click="$emit('close')">
        {{ $t('general.action.ok') }}
      </XButton>
    </template>
  </XModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--space-medium);
}
</style>
