import { buildURLParams } from '@base/utils/build-url-params';
import { useStore } from '@/store';
import { routes as views } from '.';

const routes = async ({ viewName, props, path, queryParams, url }) => {
  const store = useStore();
  const links = store.getters['info/getRoutes'];
  const toggles = store.getters['info/getToggles'];

  const view = views.find((v) => {
    const findedByPath = !!path && v.path === path && !v.path.includes('/');
    const findedByViewName = !!v.view && [v.view].flat().includes(viewName);
    return findedByPath || findedByViewName;
  });

  if (!view) return { isVueRouter: true };

  let pathname;
  if (url) pathname = new URL(url).pathname;

  const rawPath = pathname || links[path] || props?.initialLinks?.self;
  const pathWithParams = `${rawPath}${buildURLParams(queryParams)}`;
  let component;

  try {
    component = await view.component(toggles);
  } catch (error) {
    const errorToCapture = error instanceof TypeError ? new TypeError(error) : error;
    throw errorToCapture;
  }

  const componentData = {
    component,
    pathWithParams,
    props: view.props,
  };
  return componentData;
};

export default routes;
