import { camelCase } from 'lodash';

const arrayfyQuery = (query) => (Array.isArray(query) ? query : [query]);

export const sanitizeDateFilters = (dates) => {
  const [datesQuery] = dates;

  const dateKind = arrayfyQuery(datesQuery.date_kind);
  const startDate = arrayfyQuery(datesQuery.start_date);
  const endDate = arrayfyQuery(datesQuery.end_date);

  return dateKind.reduce(
    (acc, _, index) => ({
      ...acc,
      [camelCase(dateKind[index])]: { start: startDate[index], end: endDate[index] },
    }),
    {}
  );
};
