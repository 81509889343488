import { inject, computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { legacyPropsKey } from '@base/keys';

export const useLegacyProps = () => {
  const legacyProps = inject(legacyPropsKey, {});

  const route = useRoute();

  const legacyPropsByRoute = computed(() => {
    return route.meta?.legacyProps ? legacyProps : {};
  });

  return { legacyPropsByRoute };
};
