<template>
  <div :class="$style.container">
    <h3 :class="$style.title">
      {{ $t('subscription.blockedFunctionality.customSender.title') }}
    </h3>
    <p :class="$style.text">
      {{ $t('subscription.blockedFunctionality.customSender.firstText') }}
    </p>
    <i18n
      :class="$style.text"
      tag="p"
      path="subscription.blockedFunctionality.customSender.lastText"
    >
      <XLink
        :to="$t('links.help.docs.customSender')"
        :class="$style.link"
        target="_blank"
        data-testid="moreInfoLink"
      >
        {{ $t('subscription.blockedFunctionality.customSender.more') }}
      </XLink>
    </i18n>
  </div>
</template>

<script>
export default {
  name: 'CustomSenderFunctionality',
};
</script>

<style lang="scss" module>
.container {
  margin-top: var(--space-large);

  .title {
    margin-bottom: var(--space-large);
  }

  .text {
    margin-bottom: var(--space-medium);
  }

  .link {
    margin: 0;
  }
}
</style>
