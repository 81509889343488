<template>
  <section>
    <XButton
      model="icon"
      design="link"
      data-testid="headerHubspotHelpFormButton"
      @click="handleOpen"
    >
      <XIcon
        size="lg"
        icon="comment"
      />
    </XButton>
    <Transition
      name="slide-fade"
      :enter-active-class="$style.slideFadeEnterActive"
      :leave-active-class="$style.slideFadeLeaveActive"
      :enter-class="$style.slideFadeEnterFrom"
      :leave-to-class="$style.slideFadeLeaveTo"
    >
      <XCard
        v-show="isChatOpen"
        elevation="high"
        :class="$style.container"
        data-testid="headerHubspotHelpFormContainer"
      >
        <XSpinner
          v-if="isLoading"
          show-text
          vertical
        />
        <Transition
          name="slide-fade"
          :enter-active-class="$style.slideFadeEnterActive"
          :enter-class="$style.slideFadeEnterFrom"
        >
          <div
            v-show="!isLoading"
            :class="$style.content"
          >
            <XButton
              model="icon"
              design="link"
              size="xs"
              data-testid="closeButton"
              :class="$style.closeButton"
              @click="handleClose"
            >
              <XIcon icon="close" />
            </XButton>
            <h4>{{ $t('layout.header.helpForm.title') }}</h4>
            <div :id="HUBSPOT_CONTAINER_ID"></div>
          </div>
        </Transition>
      </XCard>
    </Transition>
  </section>
</template>

<script setup>
import { computed, ref } from 'vue';

import { useStore } from '@/store';

const HUBSPOT_SCRIPT_SRC = '//js.hsforms.net/forms/embed/v2.js';
const HUBSPOT_SCRIPT_ID = 'hubspot_chat_script';
const HUBSPOT_CONTAINER_ID = 'hubspot_chat_container';
const HUBSPOT_FORM_ID = '5adfcbf0-284c-4a8a-9d3f-dc3fd7699ed0';
const RESET_SCRIPT_DELAY = 1000;

const isChatOpen = ref(false);
const isChatEmbedded = ref(false);
const isLoading = ref(true);

const store = useStore();
const user = computed(() => store.getters['info/getUsers']?.user || store.getters['info/getUser']);
const accountId = computed(() => store.getters['info/getAccount']?.id);

function setHubspotHiddenInputs(form) {
  const inputEmail = form.querySelector('input[name="email"]');
  const inputAccountId = form.querySelector('input[name="account_id"]');

  inputEmail.value = user.value.email;
  inputEmail.blur();

  if (accountId.value) {
    inputAccountId.value = accountId.value;
    inputAccountId.blur();
  }
}

function resetHubspotScript() {
  setTimeout(() => {
    document.getElementById(HUBSPOT_SCRIPT_ID).remove();
    document.getElementById(HUBSPOT_CONTAINER_ID).innerHTML = '';

    isLoading.value = true;
  }, RESET_SCRIPT_DELAY);
}

function startHubspotChat() {
  if (isChatEmbedded.value) return;

  isLoading.value = true;

  window.hbspt.forms.create({
    portalId: '23756673',
    target: `#${HUBSPOT_CONTAINER_ID}`,
    formId: HUBSPOT_FORM_ID,
    onFormReady($form) {
      const form = $form[0];

      setHubspotHiddenInputs(form);

      isLoading.value = false;
    },
    onFormSubmit() {
      isChatEmbedded.value = false;
    },
  });

  isChatEmbedded.value = true;
}

function addHubspotScript() {
  const script = document.createElement('script');

  script.setAttribute('src', HUBSPOT_SCRIPT_SRC);
  script.setAttribute('id', HUBSPOT_SCRIPT_ID);
  script.onload = () => {
    startHubspotChat();
  };

  document.head.appendChild(script);
}

function checkIfHubspotScriptExists() {
  return document.getElementById(HUBSPOT_SCRIPT_ID);
}

function handleOpen() {
  isChatOpen.value = true;

  if (checkIfHubspotScriptExists()) {
    startHubspotChat();
  } else {
    addHubspotScript();
  }
}

function handleClose() {
  isChatOpen.value = false;

  if (!isChatEmbedded.value) {
    resetHubspotScript();
  }
}
</script>

<style lang="scss" module>
.container {
  position: fixed;
  z-index: var(--zindex-fixed);
  right: var(--space-regular);
  bottom: var(--space-regular);
  width: 100%;
  max-width: 368px;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .closeButton {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  @include breakpoint('extra-small') {
    width: calc(100% - var(--space-large));
  }
}

.slideFadeEnterActive,
.slideFadeLeaveActive {
  transition: all var(--time-transition-base) cubic-bezier(1, 0.5, 0.8, 1);
}

.slideFadeEnterFrom,
.slideFadeLeaveTo {
  opacity: 0;
  transform: translateY(var(--space-regular));
}
</style>
