<template>
  <button
    :class="$style.button"
    @click="toggleSidebar"
  >
    <XIcon
      icon="bars"
      size="2x"
    />
  </button>
</template>

<script>
import useGA from '@base/hooks/useGA';

export default {
  props: {},
  methods: {
    useGA,
    dispatchGA() {
      this.useGA('event', 'collapseHideMenuClick', 'menu-app');
    },
    toggleSidebar() {
      this.$emit('toggle');
      this.dispatchGA();
    },
  },
};
</script>

<style lang="scss" module>
.button {
  width: 40px;
  background: none;
  border: none;
}
</style>
