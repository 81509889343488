<template>
  <Component
    :is="componentConstructor"
    :key="router.path"
    v-bind="component.props"
  />
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useStore } from '@/store';

export default defineComponent({
  name: 'RouterView',

  setup() {
    const store = useStore();
    const router = computed(() => store.getters['info/getRouter']);
    const component = computed(() => router.value.component);
    const componentConstructor = computed(
      () => component.value.constructor || { template: '<span />' }
    );

    return {
      router,
      component,
      componentConstructor,
    };
  },
});
</script>
