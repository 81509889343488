<template>
  <div
    :class="$style.brand"
    :style="brandingColor"
  >
    <a
      :class="$style.link"
      @click="clickOnLogo"
    >
      <img
        v-if="logoUrl"
        :src="logoUrl"
        :class="$style.image"
      />

      <XLogo
        v-else
        height="32"
      ></XLogo>
    </a>
  </div>
</template>

<script>
import useGA from '@base/hooks/useGA';

export default {
  name: 'SidebarBrandImage',
  props: {
    color: {
      type: String,
      default: '',
    },
    logoUrl: {
      type: String,
      default: '',
    },
    link: {
      type: [String],
      default: '',
    },
  },
  computed: {
    brandingColor() {
      return {
        backgroundColor: this.color || 'var(--color-neutral-0)',
      };
    },
  },
  methods: {
    useGA,
    clickOnLogo() {
      this.useGA('event', 'imageLogoClick', 'menu-app');

      if (this.link) {
        window.location = this.link;
      }
    },
  },
};
</script>

<style lang="scss" module>
.brand {
  display: flex;
  align-items: center;
  padding: var(--space-regular) var(--space-regular) var(--space-small-x);
  cursor: pointer;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.image {
  max-width: 220px;
  max-height: 50px;
}
</style>
