import Vue from 'vue';

let timeout = null;
let maxTimeout = null;
const MAX_WAIT = 3000;
const DEBOUNCE_WAIT = 500;

let _options = {};

const debounceScrollEvent = (el, event) => {
  const scrollEvent = new Event('reach-scroll', event);

  if (timeout === null) {
    el.dispatchEvent(scrollEvent);
  } else {
    clearTimeout(timeout);
    clearTimeout(maxTimeout);
    maxTimeout = setTimeout(() => {
      el.dispatchEvent(scrollEvent);
    });
  }
  timeout = setTimeout(() => {
    timeout = null;
    clearTimeout(maxTimeout);
  }, _options.debounceWait);
};

const getScrolledPercentage = ({ scrollHeight, scrollTop, offsetHeight }) =>
  (offsetHeight + scrollTop) / scrollHeight;

const install = ({ maxWait, debounceWait }) => {
  _options = {
    maxWait: maxWait || MAX_WAIT,
    debounceWait: debounceWait || DEBOUNCE_WAIT,
  };

  Vue.directive('scroll', {
    bind(el, binding) {
      el.addEventListener('scroll', (event) => {
        const reachScroll = binding.expression / 100;
        const scrolledPercentage = getScrolledPercentage(event.target);

        if (scrolledPercentage >= reachScroll) {
          debounceScrollEvent(el, event);
        }
      });
    },
  });
};

export default { install };
